<template>
  <div>
    <v-snackbar v-model="snackbar">
      {{ error }}
    </v-snackbar>
    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.5);"
      >
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dateFormat from "dateformat";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";

export default {
  components: {
    // VueHtml2pdf,
  },
  data() {
    return {
      loading: false,
      startDate: null,
      error: null,
      snackbar: false,
      headers: [
        { text: "", value: "image" },
        { text: "Name", value: "name" },
        { text: "Number of Serving", value: "meals" },
        { text: "Serving size", value: "serving_size" },
        { text: "Nutrition", value: "nutrition" },
        { text: "Tags", value: "tags" },
        { text: "Barcode", value: "barcode" },
      ],
      placeholderImage: require("@/assets/default_image.png"),
      data: [],
    };
  },
  methods: {
    ...mapActions({
      getSummary: "food/fetchAvailableFoods",
    }),
    async generateReport() {
      try {
        var date = new Date();
        this.startDate = dateFormat(date, "mmmm dd yyyy");
        this.loading = true;
        const res = await this.getSummary({ base64: true });
        var doc = new jsPDF("p", "pt", "a4");

        doc.setFontSize(25);

        if (this.profileType == "Freezer") {
          doc.text("Food List", doc.internal.pageSize.width / 2, 140, {
            align: "center",
          });
          doc.setFontSize(12);
          console.log(this.profile);
          doc.text(
            this.profile.organization
              ? this.profile.organization
              : `${this.profile.first_name} ${this.profile.last_name}`,
            doc.internal.pageSize.width / 2,
            160,
            { align: "center" }
          );
        } else {
          doc.text("Food List", doc.internal.pageSize.width / 2, 160, {
            align: "center",
          });
        }

        doc.setFontSize(10);
        const canvasBarCode = document.createElement("canvas");
        JsBarcode(canvasBarCode, "Distribute", { format: "CODE128" });
        const barcodeDistribute = canvasBarCode.toDataURL("image/png");
        doc.addImage(barcodeDistribute, "PNG", 480, 70, 90, 50);
        for (var i = 0; i < res.length; i++) {
          const food = res[i];
          const index = (i + 2) % 8;
          const cellHeight = 95;
          const topPadding = 85;
          const textPadding = 20;
          const tableHeaderTop = 30;
          ///Add table header
          if (i == 0) {
            doc.text(
              "Barcode",
              130,
              topPadding + cellHeight * index - tableHeaderTop,
              {
                maxWidth: 90,
              }
            );
            doc.text(
              "Name",
              230,
              topPadding + cellHeight * index - tableHeaderTop,
              {
                maxWidth: 70,
              }
            );
            doc.text(
              "Number of Serving",
              305,
              topPadding + cellHeight * index - tableHeaderTop,
              {
                maxWidth: 55,
                align: "center",
              }
            );
            doc.text(
              "Serving size",
              365,
              topPadding + cellHeight * index - tableHeaderTop,
              {
                maxWidth: 50,
                align: "center",
              }
            );
            doc.text(
              "Nutrition",
              410,
              topPadding + cellHeight * index - tableHeaderTop,
              { maxWidth: 70 }
            );
            doc.text(
              "Tags",
              480,
              topPadding + cellHeight * index - tableHeaderTop,
              {
                maxWidth: 70,
              }
            );

            doc.line(
              20,
              topPadding + cellHeight * index - tableHeaderTop + 20,
              doc.internal.pageSize.width - 20,
              topPadding + cellHeight * index - tableHeaderTop + 20
            );
          }

          doc.addImage(
            food.image ? food.image : this.placeholderImage,
            "PNG",
            20,
            topPadding + cellHeight * index,
            90,
            60
          );

          const canvas = document.createElement("canvas");
          JsBarcode(canvas, food.barcode, { format: "CODE128" });
          const barcodeData = canvas.toDataURL("image/png");
          doc.addImage(
            barcodeData,
            "PNG",
            125,
            topPadding + cellHeight * index,
            90,
            50
          );
          doc.text(
            food.name,
            230,
            textPadding + topPadding + cellHeight * index,
            {
              maxWidth: 70,
            }
          );
          doc.text(
            `${food.meals}`,
            300,
            textPadding + topPadding + cellHeight * index,
            {
              maxWidth: 55,
            }
          );

          if (food.serving_size) {
            doc.text(
              `${food.serving_size}`,
              355,
              textPadding + topPadding + cellHeight * index,
              {
                maxWidth: 55,
              }
            );
          }

          var nutritions = [];
          if (food.fruits) nutritions.push(`Fruits: ${food.fruits}`);
          if (food.vegetables)
            nutritions.push(`Vegetables: ${food.vegetables}`);
          if (food.grains) nutritions.push(`Grains: ${food.grains}`);
          if (food.protein) nutritions.push(`Protein: ${food.protein}`);
          if (food.dairy) nutritions.push(`Dairy: ${food.dairy}`);
          if (nutritions.length)
            doc.text(
              nutritions,
              410,
              textPadding + topPadding + cellHeight * index,
              { maxWidth: 70 }
            );
          doc.text(
            food.tags,
            480,
            textPadding + topPadding + cellHeight * index,
            {
              maxWidth: 70,
            }
          );

          if (index == 7 && i != res.length - 1) {
            doc.addPage();
            doc.setPage(parseInt((i + 14) / 8));

            doc.text("Barcode", 130, topPadding - tableHeaderTop, {
              maxWidth: 90,
            });
            doc.text("Name", 230, topPadding - tableHeaderTop, {
              maxWidth: 70,
            });
            doc.text("Number of Serving", 300, topPadding - tableHeaderTop, {
              maxWidth: 55,
              align: "center",
            });
            doc.text("Serving size", 355, topPadding - tableHeaderTop, {
              maxWidth: 50,
              align: "center",
            });
            doc.text("Nutrition", 410, topPadding - tableHeaderTop, {
              maxWidth: 70,
            });
            doc.text("Tags", 480, topPadding - tableHeaderTop, {
              maxWidth: 70,
            });

            doc.line(
              20,
              topPadding - tableHeaderTop + 20,
              doc.internal.pageSize.width - 20,
              topPadding - tableHeaderTop + 20
            );
          }
        }

        doc.addPage();
        doc.addImage(barcodeDistribute, "PNG", 255, 180, 90, 50);

        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("times", "bold");
        for (var pi = 1; pi <= pageCount; pi++) {
          doc.setPage(pi);
          doc.setFontSize(20);
          doc.text("Daniel's Table", doc.internal.pageSize.width - 150, 30);
          // doc.setLineWidth(0, 5);
          doc.line(20, 40, doc.internal.pageSize.width - 20, 40);
          doc.setFontSize(12);
          doc.text(
            "Page " + String(pi) + " of " + String(pageCount),
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.height - 10,
            {
              align: "center",
            }
          );
        }

        if (this.profileType == "Freezer") {
          doc.save(
            `Food List - ${
              this.profile.organization
                ? this.profile.organization
                : this.profile.first_name +
                  (this.profile.last_name ? " " + this.profile.last_name : "")
            }.pdf`
          );
        } else {
          doc.save("Food List.pdf");
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
        if (error.response) {
          this.snackbar = true;
          this.error = error.response.data.message;
        } else {
          this.snackbar = true;
          this.error = error;
        }
        console.log(error);
      }
    },
    onDone() {
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters("auth", {
      profile: "getProfile",
      profileType: "getType",
    }),
  },
  mounted() {
    this.generateReport();
  },
};
</script>
<style>
.data-table {
  border: 0.5px solid gray;
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
  table-layout: fixed;
  word-wrap: break-word;
}
td {
  text-align: center;
  padding: 10px;
}
.right-border {
  border-right: 0.5px solid gray;
}
.bottom-border {
  border-bottom: 0.5px solid gray;
}
.top-border {
  border-top: 0.5px solid gray;
}
.wrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}
</style>
